<template>
    <div>
      
      <v-card style="min-width: 400px; max-width: 800px;" class="mx-auto mt-16 mb-32">
        <v-row class="mt-4 ml-2">
          <v-col lg="6" sm="12">
            <template v-for="item in companies_with_shop">

              <div v-bind:key="item.company.company_id">

                <hr/>

                <v-row>
                  <b-col>
                    <FormShopCompanyMembershipBox
                      v-if="item.shop_items.length > 0"
                      class="mt-8"
                      :age="18"
                      :company_shop_items="item"
                      :preselected_shop_item_id="item.shop_items.length === 1 ? item.shop_items[0].shop_item_id : null"
                      @on_shop_item_selected="on_company_membership_selected"
                    />
                  </b-col>
                </v-row>
              </div>

            </template>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            
            <FormShopCartTable
              :total_amount="total_amount"
              :total_amount_vat="total_amount_vat"
              :cart="local_form_page_data.cart"
              :discount="discount"
              :currency="currency"
              @delete_item_clicked="delete_item_clicked"
            />

          </v-col>
        </v-row>
        
        <p v-if="show_error" class="mt-8" style="color: #fd397a;">{{ $t('PUBLIC.FORM.MISSING_FIELDS') }}</p>
        
        <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100">
          <b-button
            class="mt-4 mr-4"
            variant="outline-secondary"
          >
            {{ $t('COMMON.BACK') }}
          </b-button>
          
          <b-button
            class="mt-4"
            variant="primary"
            :type="type"
            :disabled="disabled || spinning"
            @click.prevent="on_click"
          >
            <b-spinner v-if="spinning" small type="grow"></b-spinner>
            {{ $t('COMMON.CONTINUE') }}
          </b-button>
        </div>
        
      </v-card>
  
    </div>
  </template>
  
  <script>
  import dayjs from 'dayjs';
  import axios from 'axios';
  
  import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
  
  import FormShopCompanyMembershipBox from '@/view/pages/public/forms/pages/shop/FormShopCompanyMembershipBox.vue';
  import FormShopCartTable from '@/view/pages/public/forms/pages/shop/FormShopCartTable.vue';

  
  export default {
    name: 'TestFormShopCompanyMembership',
    mixins: [ toasts ],

    components: {
      FormShopCompanyMembershipBox,
      FormShopCartTable
    },
  
    props: {
      
      form_page_data: { /* data for the entire form, previously entered, all pages */
        type: Object,
        default: () => ({})
      },
      form_page: { /* page definition for this page, ml_form_page */
        type: Object,
        default: () => ({})
      }
    },
  
    data() {
      return {

        memberships_in_cart: [],

        companies_with_shop: [
          {
            company: {
              company_id: "1",
              name: "Prisma 1"
            },
            shop_items: [
              {
                shop_item_id: 1,
                name: "Artikel förening 1",
                description: "Detta är en artikel 1",
                amount: 100,
                amount_vat: 0,
                currency: "SEK",
                rules: {
                  min_age: 0,
                  max_age: 200
                }
              },
              {
                shop_item_id: 2,
                name: "Artikel förening 1",
                description: "Detta är en artikel 2",
                amount: 101,
                amount_vat: 0,
                currency: "SEK",
                rules: {
                  min_age: 0,
                  max_age: 200
                }
              }
            ]
          },
          {
            company: {
              company_id: "2",
              name: "Prisma Subcompany"
            },
            shop_items: [
              {
                shop_item_id: 3,
                name: "Artikel förening 2",
                description: "Detta är en artikel igen",
                amount: 150,
                currency: "SEK",
                rules: {
                  min_age: 0,
                  max_age: 200
                }
              }
            ]
          }
        ],

        discount: 0,
        total_amount: 0,
        total_amount_vat: 0,

        local_form_page_data: {
          cart: [],
        },
  
        skip: false,
  
        force_validate: 0,
        is_form_valid: false,
        show_error: false,
        spinning: false,
        disabled: false,
        type: 'primary'
      };
    },
    mounted() {

      if (this.form_page_data) {
        this.local_form_page_data = { ...this.form_page_data };

        if (!this.local_form_page_data.cart) {
          this.local_form_page_data.cart = [];
        }
      }
      
    },
    watch: {
      form_page_data: {
        handler(newValue) {
          if (this.form_page_data) {
            this.local_form_page_data = { ...this.form_page_data };

            if (!this.local_form_page_data.cart) {
              this.local_form_page_data.cart = [];
            }
          }
        },
        deep: true
      }
    },
    computed: {
      currency() {

        if (!this.companies_with_shop) {
          return 'SEK';
        }

        for (const comp of this.companies_with_shop) {
          const item_with_currency = comp.shop_items.find(item => item.currency) || { currency: 'SEK' };

          return item_with_currency.currency;
        }

        return 'SEK';
      },
    },
    methods: {

      delete_item_clicked(shop_item_id) {
        for (var i = 0; i < this.cart.length; ++i) {
          if (this.cart[i].shop_item_id === shop_item_id) {
            this.cart.splice(i, 1);
            break;
          }
        }

        for (var i = 0; i < this.extras.length; ++i) {
          if (this.extras[i].shop_item_id === shop_item_id) {
            this.$refs.shopItemRow[i].clear();
            break;
          }
        }
      },
      
      on_company_membership_selected(company_id, shop_item_id) {

        if (!company_id) {
          throw 'on_company_membership_selected: company id not set';
        }
        
        if (!shop_item_id) {
          throw 'on_company_membership_selected: shop item id not set';
        }
        
        const int_shop_item_id = parseInt(shop_item_id+'');

        const company_memberships = this.companies_with_shop.find(msi => msi.company.company_id === company_id );

        if (!company_memberships || company_memberships.length === 0) {
          console.error('no company memberships found for company ', company_id);
          return;
        }

        // remove any potentially previously selected shop items for the company
        this.local_form_page_data.cart = this.local_form_page_data.cart.filter(ci => ci.company_id !== company_id);
        this.memberships_in_cart = this.memberships_in_cart.filter(ci => ci.company_id !== company_id);

        // proceed to find the membership actually selected
        const selected_membership = company_memberships.shop_items.find(msi => msi.shop_item_id === int_shop_item_id);

        if (!selected_membership) {
          console.error('selected membership not found', int_shop_item_id);
          return;
        }

        // keep track of quantity and company_id
        const extended_selected_membership = { ...selected_membership, company_id: company_id, quantity: 1, is_membership: true };

        this.memberships_in_cart.push(extended_selected_membership);
        this.local_form_page_data.cart.push(extended_selected_membership);

        this.local_form_page_data = { ...this.local_form_page_data };
      },


      on_back() {
        this.$emit('back', this.form_page, this.local_form_page_data);
      },
  
      skip_clicked() {},
  
      update_form(field_name, value) {
        this.local_form_page_data[field_name] = value;
  
        this.local_form_page_data = { ...this.local_form_page_data };
      },
      validated(field_name, value, valid) {
        this.local_form_page_data[field_name] = value;
  
        if (!valid) {
          this.is_form_valid = false;
        }
      },
  
      async on_click() {
        this.spinning = true;
        this.show_error = false;
  
        this.is_form_valid = true;
        this.force_validate = this.force_validate + 1;
  
        this.$nextTick(()=>{
          if (this.is_form_valid) {
            this.spinning = false;
  
            this.$emit('next', this.form_page, this.local_form_page_data);
          }
          else {
            this.show_error = true; 
  
            setTimeout(()=>{
              this.spinning = false;
            }, 2000);
          }
        });
      },
  
    }
  };
  </script>
  