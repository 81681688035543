<template>

  <div>
    <div v-if="is_mobile">
      <h4>{{$t('SHOP_ORDER.AMOUNT')}}: {{ total_price }} {{ currency }}</h4>
    </div>
    <div v-else>
      <v-row class="cart-header">
        <v-col cols="6">
          <h4>{{$t('SHOP_ORDER.ARTICLE')}}</h4>
        </v-col>

        <v-col cols="2" class="text-right">
          <h4>{{$t('SHOP_ORDER.COUNT')}}</h4>
        </v-col>

        <v-col cols="2" class="text-right">
          <h4>{{$t('SHOP_ORDER.AMOUNT')}}</h4>
        </v-col>

        <v-col cols="1"></v-col>
      </v-row>

      <template v-for="(item, i) in cart">
        <FormShopCartTableRow
          v-bind:key="i"
          :item="item"
          @delete_item_clicked="delete_item_clicked"
        />
      </template>

      <v-row class="mt-0" v-if="discount">
        <v-col cols="6">
          <v-row>
            <v-col cols="2"></v-col>
            <v-col cols="10" class="item-descr">
              <h5 style="margin-top: 10px;">{{ discount.campaign_name }}</h5>
              <p>{{ discount.campaign_descr }}</p>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="2">
        </v-col>

        <v-col cols="2" class="amounts-cols text-right">
          <h5 class="item-discount">-{{ discount.total_discount }} {{ currency }}</h5>
        </v-col>

        <v-col cols="1"></v-col>
      </v-row>

      <v-row class="cart-header mt-2">
        <v-col cols="6">
          <h4></h4>
        </v-col>

        <v-col cols="2" class="text-right">
          <h4>{{$t('SHOP_ORDER.TOTAL')}}</h4>
        </v-col>

        <v-col cols="2" class="text-right">
          <h4>{{ total_amount }} {{ currency }}</h4>
        </v-col>

        <v-col cols="1"></v-col>
      </v-row>
    </div>

  </div>

</template>

<script>
import FormShopCartTableRow from '@/view/pages/public/forms/pages/shop/FormShopCartTableRow.vue';
import { is_mobile } from '@/core/services/utils';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'FormShopCartTable',
  props: ['cart', 'discount', 'currency'],
  emits: ['delete_item_clicked'],
  mixins: [ toasts ],
  components: {
    FormShopCartTableRow
  },
  computed: {
    is_mobile() {
      return is_mobile();
    },
    total_amount() {
      let amount = 0;

      for (const row of this.cart) {
        amount += row.amount;
      }

      return amount;
    },
    total_amount_vat() {
      let amount_vat = 0;

      for (const row of this.cart) {
        amount_vat += row.amount_vat;
      }

      return amount_vat;
    }
  },
  methods: {
    delete_item_clicked(shop_item_id) {
      this.$emit('delete_item_clicked', shop_item_id);
    }
  },
  data() {
    return {
      list: []
    };
  }
};
</script>
<style scoped>

  :deep .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .cart-header h4 {
    text-transform: uppercase;
    padding: 8px 0px;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    color: #6c6c6c;
  }
  .cart-header {
    background-color: #efeff2;
  }
  .item-icon {
    max-width: 64px;
  }
  .item-descr {

  }
  .item-descr h5 {
    margin-top: 10px;
  }
  .item-descr p {
    font-style: italic;
    color: #8a8a8a;
  }
  .item-discount {
    color: #fd397a;
  }
  .table > thead > tr > th:first-child, .table > th:last-child {
    border-radius: 0;
  }
  .amounts-cols h5 {
    margin-top: 10px;
    margin-left: 10px;
    font-weight: 600;
    font-size: 1rem;
  }
</style>
