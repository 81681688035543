<template>
  <div>

      <div class="mb-0" style="clear: both;">
        <form>
          <div class="pb-2">
            <h4 class="mt-4 mb-4">{{company_shop_items.company.name}}</h4>
            <template v-for="(shopitem, k) in company_shop_items.shop_items">
              <FormShopPrettyOption
                v-if="is_within_age(shopitem.rules)"
                class="mb-8"
                v-bind:key="k"
                name="shopitem"
                :preselected="shopitem.shop_item_id === (preselected_shop_item_id || selected_shop_item_id)"
                :value="shopitem.shop_item_id"
                :img_url="shopitem.img_url"
                :title="shopitem.name"
                :descr="shopitem.descr"
                :amount="shopitem.amount"
                :currency="shopitem.currency"
                @on_value_changed="on_select_shop_item"
              />
            </template>
          </div>
        </form>
      </div>
  </div>
</template>

<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}

.price {
  color: #646c9a;
  font-weight: 600;
}

.hdr {
  font-weight: bold;
}
</style>
<script>

import FormShopPrettyOption from '@/view/pages/public/forms/pages/shop/FormShopPrettyOption.vue';
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'FormShopCompanyMembershipBox',
  mixins: [ toasts ],
  props: ['company_shop_items', 'preselected_shop_item_id', 'age'],
  emits: ['on_shop_item_selected'],
  components: {
    FormShopPrettyOption,
  },
  computed: {
    
  },
  watch: {
    
    selected_shop_item_id(newValue, oldValue) {
      if (newValue) {
        this.on_select_shop_item(newValue);
      }
    }
  },
  data() {
    return {
      selected_shop_item_id: null
    };
  },
  mounted() {
    this.selected_shop_item_id = this.preselected_shop_item_id;

    this.$emit('on_shop_item_selected', this.company_shop_items.company.company_id, this.selected_shop_item_id);
  },
  methods: {
    getAge(date) {
      return dayjs().diff(
        date,
        'years'
      );

      return 18;
    },
    determineAge(pagedata) {
      if (pagedata.details && pagedata.details.dob) {
        return this.getAge(pagedata.details.dob);
      }
      if (pagedata.personnr && pagedata.personnr.personnr) {
        return this.getAge(pagedata.personnr.personnr.substring(0,8));
      }
      return 18;
    },
    is_within_age(rules) {

      if (this.age) {
        const age_min = parseInt(rules.age_min+'');
        const age_max = parseInt(rules.age_max+'');

        if (age_min === 0 && age_max === 0) {
          return true;
        }

        if (!isNaN(age_min) && !isNaN(age_max)) {
          return this.age >= age_min && age <= age_max;
        }
        if (!isNaN(age_min)) {
          return this.age >= age_min;
        }
        if (!isNaN(age_max)) {
          return this.age <= age_max;
        }
      }

      return true;
    },
    on_select_shop_item(shop_item_id) {
      this.selected_shop_item_id = shop_item_id;
      this.$emit('on_shop_item_selected', this.company_shop_items.company.company_id, shop_item_id);
    }
  }
};
</script>
